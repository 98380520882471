import { BOARD_BASE_FRAGMENT } from '#board/fragment'
import type { BaseBoard } from '#board/types'

/**
 * This composable is used to update the board state in the workspace shared state
 * when the board cache is updated.
 */
export const useBoardStateUpdater = () => {
  const { currentBoard, setCurrentBoard } = useWorkspaceSharedState()

  const subscriber = ref()

  watch(
    () => currentBoard.value.id,
    (boardId) => {
      if (boardId) {
        subscriber.value?.unsubscribe()
        useWatchFragment({
          fragment: BOARD_BASE_FRAGMENT,
          fragmentName: 'BaseBoard',
          id: {
            id: boardId,
            __typename: 'BoardType',
          },
          onUpdate: (snapshot) => {
            setCurrentBoard(snapshot.data as BaseBoard)
          },
        })
      }
    },
    { immediate: true }
  )

  onUnmounted(() => {
    subscriber.value?.unsubscribe()
  })
}
