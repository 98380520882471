import {
  MessageType,
  type ServerCustomMessagePayload,
  type ServerNotifyMessagePayload,
} from '#core/types/packages/socket'
import { SECTION_TYPE_NAME } from '#section/constant'
import { TASK_TYPE_NAME } from '#task/constant'
import { USER_TYPE_NAME } from '#auth/constant'
import { LABEL_TYPE_NAME } from '#label/constant'
import { STATUS_TYPE_NAME } from '#status/constant'
import { TASK_TYPE_TYPE_NAME } from '#task-type/constant'
import { FIELD_TYPE_NAME } from '#field/constant'
import { ATTACHMENT_TYPE_NAME } from '#attachment/constant'
import { SETTINGS_PACK_TYPE_NAME } from '#settings-pack/constant'

type RegisterHandler = () => {
  sync: (payload: ServerNotifyMessagePayload) => void
}

type RegisterCustomHandler = () => {
  handle: (payload: ServerCustomMessagePayload) => void
}

export const useBoardRealtimeContext = () => {
  const { socket } = useSocket()

  const messageHandler = ref()

  const subscribe = (boardId: string) => {
    socket.value?.send({
      type: MessageType.Subscribe,
      payload: {
        modelId: boardId,
        modelType: 'Board',
      },
    })
    messageHandler.value = socket.value?.on('message', (message) => {
      if (message.type === 'model') {
        const { payload } = message
        const register = new Map<string, RegisterHandler>([
          ['BoardType', useBoardSync],
          [SECTION_TYPE_NAME, useSectionSync],
          [TASK_TYPE_NAME, useTaskSync],
          [ATTACHMENT_TYPE_NAME, useAttachmentSync],
          ['TaskLabelType', useTaskLabelSync],
          [STATUS_TYPE_NAME, useStatusSync],
          [LABEL_TYPE_NAME, useLabelSync],
          [TASK_TYPE_TYPE_NAME, useTaskTypeSync],
          [FIELD_TYPE_NAME, useFieldSync],
          ['TaskFieldType', useTaskFieldsSync],
          [USER_TYPE_NAME, useUserSync],
          ['ActionType', useActionSync],
          ['CommentType', useCommentSync],
          ['ReactionType', useReactionSync],
          [SETTINGS_PACK_TYPE_NAME, useSettingsPackSync],
          ['TaskTypeFieldType', useTaskTypeFieldSync],
        ])
        /**
         * Delay 100ms to make sure the cache is updated before sync
         */
        sleep(100).then(() => {
          logger.info('[Socket] Sync message received', payload.action, payload.model.__typename, payload.model.id, payload.model)
          queueMicrotask(() => {
            // Convert payload.action to ADD if FORCE_ADD
            if (payload.action === 'FORCE_ADD') {
              payload.action = 'ADD'
            }

            register.get(payload.model.__typename)?.().sync(payload)
          })
        })
      }

      if (message.type === 'custom') {
        logger.info('[Socket] Message received', message)
        const { payload } = message
        const register = new Map<string, (RegisterCustomHandler)>([
          ['IMPORT_TASKS', useImportTasksSync],
        ])
        /**
         * Delay 100ms to make sure the cache is updated before sync
         */
        sleep(100).then(() => {
          logger.info('[Socket] Sync message received', payload)
          queueMicrotask(() => {
            register.get(payload.action)?.().handle(payload)
          })
        })
      }
    })
  }

  const unsubscribe = (boardId: string) => {
    messageHandler.value?.unsubscribe()
    socket.value?.send({
      type: MessageType.Unsubscribe,
      payload: {
        modelId: boardId,
        modelType: 'Board',
      },
    })
  }

  return {
    subscribe,
    unsubscribe,
  }
}
