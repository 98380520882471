import { pick } from 'lodash-es'
import type { ServerCustomMessagePayload, ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { BaseBoard } from '#board/types'
import type { FieldsModifier } from '#core/types'

export const useBoardSync = () => {
  const { client } = useApolloClient()

  const getSyncFields = (payload: Partial<BaseBoard>) => {
    const fields: FieldsModifier<BaseBoard> = {
      name: () => payload.name,
      visibility: () => payload.visibility,
      backgroundColor: () => payload.backgroundColor,
      backgroundType: () => payload.backgroundType,
      backgroundPhoto: () => payload.backgroundPhoto,
      description: () => payload.description,
      closed: () => payload.closed,
    }

    return pick(fields, Object.keys(payload)) as FieldsModifier<BaseBoard>
  }

  const onUpdate = (payload: Partial<BaseBoard>) => {
    client.cache.modify({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'BoardType',
      }),
      fields: getSyncFields(payload),
    })
  }

  const onDelete = async (payload: Partial<BaseBoard>) => {
    await navigateTo('/')
    client.cache.evict({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'BoardType',
      }),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<BaseBoard>) => void
        >([
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}

export const useImportTasksSync = () => {
  const handle = (payload: ServerCustomMessagePayload) => {
    const { importFiles, showButtonImport } = useBoardImportTasksSharedState()
    const toast = useToast()
    const { auth } = useAuthStore()

    const { data, board_id: boardId } = payload
    const { file_name: fileName, success, actor_id: actorID, num_imported: numImported } = data

    const isFileNameExist = importFiles.value.some(file => file.fileName === fileName && file.actorId === actorID)
    const totalTask = parseInt(localStorage.getItem(`importFiles_${fileName}_total`) || '0')
    if (totalTask === 0) {
      localStorage.removeItem(`importFiles_${fileName}_total`)
      return
    }

    if (!isFileNameExist && auth.id === actorID && success) {
      showButtonImport.value = true
      importFiles.value.push({
        fileName,
        boardId,
        actorId: actorID,
        remain: totalTask,
        status: 'importing',
        total: totalTask,
      })
    }

    importFiles.value.map((file) => {
      if (file.fileName === fileName && file.boardId === boardId) {
        if (success) {
          file.remain = file.total - numImported
        } else {
          file.status = 'failed'
          localStorage.removeItem(`importFiles_${fileName}_total`)
          return
        }

        if (file.remain === 0) {
          file.status = 'completed'
          toast.add({
            title: 'Imported task successfully',
            description: `All tasks from ${fileName} have been imported successfully`,
            icon: 'i-heroicons-check-circle',
          })
          // Hide button after 1 minute
          setTimeout(() => {
            showButtonImport.value = false
          }, 60000)
          localStorage.removeItem(`importFiles_${fileName}_total`)
        }
      }

      return file
    })
  }

  return {
    handle,
  }
}
