import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { UserLoader, UserSyncPayload } from '#auth/types'
import { USER_LOADER_FRAGMENT } from '#auth/loader_fragment'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { USER_TYPE_NAME } from '#auth/constant'

class UserListCache extends ModelListCache<UserLoader> {
  constructor() {
    super(USER_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'users',
    }]
  }
}

export const useUserSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new UserListCache()
  const { getUserIdentifier, getSyncUserFields } = useUserDataConvert()

  const onAdd = async (payload: UserSyncPayload) => {
    client.cache.writeFragment({
      data: payload,
      fragment: USER_LOADER_FRAGMENT,
      fragmentName: 'UserLoader',
    })

    // Add the new item to the list cache
    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: UserSyncPayload) => {
    client.cache.modify({
      id: getUserIdentifier(payload.id),
      fields: getSyncUserFields(payload),
    })

    // Update the item in the list cache
    listCache.update(currentBoard.value.id, payload)
  }

  const onDelete = (payload: UserSyncPayload) => {
    // Don't delete from cache, only delete from list cache
    listCache.delete(currentBoard.value.id, payload.id)
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: UserSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}
