import { pick } from 'lodash-es'
import type { UserLoader, UserSyncPayload } from '#auth/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'
import { USER_TYPE_NAME } from '#auth/constant'

export const useUserDataConvert = () => {
  const getUserIdentifier = (id: string) => getIdentifier(id, USER_TYPE_NAME)
  const fields = ['id', 'fullName', 'photo', 'email']

  // For socket.update
  const getSyncUserFields = (payload: UserSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<UserLoader>
  }

  return {
    getUserIdentifier,
    getSyncUserFields,
  }
}
