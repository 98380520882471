import { pick, omit } from 'lodash-es'
import type { Reference } from '@apollo/client'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { ActionType, CommentType, ReactionType } from '#action/types'
import type { FieldsModifier } from '#core/types'
import { ACTION_FRAGMENT, REACTION_FRAGMENT } from '#action/fragment'

export const useActionSync = () => {
  const { client } = useApolloClient()
  const { currentTask } = useWorkspaceSharedState()
  const onAdd = async (payload: Partial<ActionType>) => {
    const newActionRef = () => {
      return client.cache.writeFragment({
        data: payload,
        fragment: ACTION_FRAGMENT,
        fragmentName: 'Action',
      })
    }

    client.cache.modify({
      fields: {
        taskActions: (existingActions = { items: [], nextCursor: '' }, { readField, storeFieldName }) => {
          const { filter } = extractStoreFieldName<{ filter: { taskId: string } }>(storeFieldName)
          if (!filter?.taskId || filter.taskId !== currentTask.value.id || payload.task?.id !== currentTask.value.id) {
            return existingActions
          }

          const isExisting = existingActions.items.some((ref: Reference) => {
            return readField('id', ref) === payload.id
          })
          if (isExisting) {
            return existingActions
          }

          return { ...existingActions, items: [newActionRef(), ...existingActions.items] }
        }
      },
    })
  }

  const onDelete = (payload: Partial<ActionType>) => {
    client.cache.evict({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'ActionType',
      }),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<ActionType>) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}

export const useCommentSync = () => {
  const { client } = useApolloClient()

  const getSyncFields = (payload: Partial<CommentType>) => {
    const fields: FieldsModifier<CommentType> = {
      id: () => payload.id,
      created: () => payload.created,
      modified: () => payload.modified,
      content: () => payload.content,
    }

    return pick(fields, Object.keys(payload)) as FieldsModifier<CommentType>
  }

  const onUpdate = (payload: Partial<CommentType>) => {
    const fields = getSyncFields(payload)
    client.cache.modify({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'CommentType',
      }),
      fields,
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<CommentType>) => void
        >([
          ['UPDATE', onUpdate],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}

export const useReactionSync = () => {
  const { client } = useApolloClient()

  const onAdd = async (payload: Partial<ReactionType & { commentId: string }>) => {
    const newActionRef = () => {
      return client.cache.writeFragment({
        data: omit(payload, 'commentId'),
        fragment: REACTION_FRAGMENT,
        fragmentName: 'Reaction',
      })
    }

    client.cache.modify({
      id: client.cache.identify({
        id: payload.commentId,
        __typename: 'CommentType',
      }),
      fields: {
        reactions: (existReaction = [], { readField }) => {
          const isExisting = existReaction.some((ref: Reference) => {
            return readField('id', ref) === payload.id
          })
          if (isExisting) {
            return existReaction
          }

          const newReaction = newActionRef()
          return [...existReaction, newReaction]
        },
      },
    })
  }

  const onDelete = (payload: Partial<ReactionType>) => {
    client.cache.evict({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'ReactionType',
      }),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<ReactionType>) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}
