import type { Reference } from '@apollo/client'
import { omit, pick } from 'lodash-es'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { AddAttachmentSync } from '#attachment/types'
import { ATTACHMENT_FRAGMENT } from '#attachment/fragment'
import type { FieldsModifier } from '#core/types'

export const useAttachmentSync = () => {
  const { client } = useApolloClient()

  const getSyncFields = (payload: Partial<AddAttachmentSync>) => {
    const fields: FieldsModifier<AddAttachmentSync> = {
      name: () => payload.name,
    }

    return pick(
      fields,
      Object.keys(payload)
    ) as FieldsModifier<AddAttachmentSync>
  }

  const onAdd = (payload: Partial<AddAttachmentSync>) => {
    const { getTaskIdentifier } = useTaskDataConvert()
    client.cache.modify({
      id: getTaskIdentifier(payload?.task?.id as string),
      fields: {
        attachments: (existingAttachments = [], { readField }) => {
          const newAttachmentRef = client.cache.writeFragment({
            data: omit(payload, 'task'),
            fragment: ATTACHMENT_FRAGMENT,
          })

          const isExisting = existingAttachments.some((ref: Reference) => {
            return readField('id', ref) === payload.id
          })

          if (isExisting) {
            return existingAttachments
          }

          return [...existingAttachments, newAttachmentRef]
        },
      },
    })
  }

  const onUpdate = (payload: Partial<AddAttachmentSync>) => {
    const { getAttachmentIdentifier } = useAttachmentDataConvert()
    client.cache.modify({
      id: getAttachmentIdentifier(payload.id as string),
      fields: getSyncFields(payload),
    })
  }

  const onDelete = (payload: Partial<AddAttachmentSync>) => {
    const { getAttachmentIdentifier } = useAttachmentDataConvert()
    client.cache.evict({
      id: getAttachmentIdentifier(payload.id as string),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<AddAttachmentSync>) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}
