import { createSocketConnection } from '#core/packages/socket'

export const $useSocket = () => {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()
  const toast = useToast()
  const { isLoggedIn, accessToken } = useAuthStore()
  const { isOnline } = useNetwork()

  const retryTimes = ref(0)
  const socket = ref<ReturnType<typeof createSocketConnection>>()

  const initialize = () => {
    if (isLoggedIn) {
      const socketUrl = `${config.public.socketApiEndpoint}?session=${accessToken}`
      socket.value = createSocketConnection({
        url: socketUrl,
        socketId: nuxtApp.$socketId,
        shouldRetry: () => {
          const shouldRetry = retryTimes.value < 5
          if (!shouldRetry) {
            toast.add({
              color: 'red',
              title: 'Lost connection to the server',
              description: 'Please refresh the page to reconnect',
            })
          }

          return shouldRetry
        },
        retryWait: () => {
          ++retryTimes.value
          return sleep(1000)
        },
        on: {
          connected: () => {
            logger.info('[Socket] Connected')
          },
          closed: () => {
            logger.info('[Socket] Disconnected')
          },
          error: () => {
            logger.info('[Socket] Error')
          },
        },
      })
    }
  }

  watch(
    () => isOnline.value,
    (online) => {
      if (online) {
        retryTimes.value = 0
        socket.value?.reconnect()
      }
    }
  )

  return {
    socket,
    initialize,
  }
}

export const useSocket = createSharedComposable($useSocket)
